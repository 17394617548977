<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>发放空投</h3>
    </header>

    <main class="x-main">
      <!-- 标题类型 -->
      <el-card class="mb30">
        <div slot="header">
          <span>基本信息：</span>
        </div>
        <el-form
          class="x-form"
          ref="titleForm"
          size="small"
          label-width="180px"
          :rules="titleRules"
          :model="form"
        >
          <el-form-item label="空投类型：" prop="airdropType">
            <el-select placeholder="请选择空投类型" v-model="form.airdropType">
              <el-option key="1" label="藏品" value="1"></el-option>
              <el-option key="2" label="盲盒" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="空投藏品/盲盒：" prop="NFTID">
            <el-select placeholder="请选择空投藏品/盲盒" v-model="form.NFTID">
              <el-option
                v-for="(item, index) in optionsNft"
                :key="index"
                :label="item.NFTName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="空投名单：" prop="versionUrl">
            <el-upload
              class="x-single-upload"
              drag
              ref="upload"
              action="#"
              name="file"
              accept=".xlsx"
              :before-upload="beforeContactUpload"
              :http-request="doContactUpload"
              :show-file-list="false"
              :file-list="versionUrlList"
              :on-change="setContactList"
            >
              <a class="font-primary" v-if="form.versionUrl" target="_blank"
                >已上传文件（Excel），点击可重新上传</a
              >
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将Excel拖到此处，或
                  <em>点击上传</em>
                </div>
              </div>
              <div class="el-upload__tip" slot="tip">
                （仅支持.xlsx格式文件，大小不超过10M）
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card class="mb30" v-if="errorInfo.length != 0">
        <div slot="header">
          <span>以下为空投名单检测错误的用户信息：</span>
        </div>

        <el-table :data="errorInfo" stripe style="width: 100%">
          <el-table-column
            prop="phone"
            label="手机号码"
            width="220"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="num"
            label="空投数量"
            width="220"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="result"
            label="错误信息"
            width="400"
            align="center"
          >
            <template slot-scope="scope">
              <span class="font-danger">{{ scope.row.result }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>

      <el-row type="flex" justify="center" class="mt5">
        <el-col :span="6">
          <el-button type="primary" @click="doValidate" :disabled="codeDisabled"
            >提交</el-button
          >
          <el-button @click="goBack">返回上级页面</el-button>
        </el-col>
      </el-row>
    </main>
  </div>
</template>

<script>
import { AirdropCreate, ExcelCheck } from "@/api/module/airdrop";
import { NftQuery } from "@/api/module/nft";

// 表单校验规则
const titleRules = {
  versionUrl: [
    {
      required: true,
      message: "请上传空投名单Excel",
      trigger: "change",
    },
  ],
  airdropType: [
    {
      required: true,
      message: "请选择空投类型",
      trigger: "change",
    },
  ],
  NFTID: [
    {
      required: true,
      message: "请选择空投藏品/盲盒",
      trigger: "change",
    },
  ],
};

export default {
  name: "MarketCreate",
  data() {
    return {
      codeDisabled: false,
      optionsNft: [],
      versionUrlList: [],
      titleRules: titleRules,
      aFileList: [],
      form: {
        versionUrl: false,
        NFTID: "",
        airdropType: "",
      },
      errorInfo: [],
    };
  },
  created() {
    this.getMarketOptions();
  },
  methods: {
    async getMarketOptions() {
      const r = await NftQuery(`?pagination=false&page=1&pageSize=10&status=3`);
      this.optionsNft = r.list;
    },
    beforeContactUpload(file) {
      if (this.form.NFTOnlineID == "" || this.form.airdropType == "") {
        this.$message.error("请选择空投类型及空投藏品盲盒后再上传文件!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 30;
      if (!sizeLimit) {
        this.$message.error("上传文件大小不能超过 30MB!");
        return false;
      }
      return true;
    },
    doContactUpload() {
      let oData = new FormData();
      const oFileList = this.versionUrlList;
      this.aFileList = oFileList;
      const len = oFileList.length - 1;
      oData.append("file", oFileList[len].raw);
      oData.append("NFTID", this.form.NFTID);
      oData.append("airdropType", this.form.airdropType);
      ExcelCheck(oData).then((r) => {
        if (r.code !== 0) return;
        if (r.code == 0) {
          this.form.versionUrl = true;
          this.errorInfo = r.data.list;
        }
      });
      this.versionUrlList = [];
    },
    // 选择合同
    setContactList(file, fileList) {
      this.versionUrlList = fileList;
    },
    // 验证表单
    doValidate() {
      this.$refs["titleForm"].validate((valid) => {
        if (valid) {
          this.codeDisabled = true;
          this.doSubmit();
        } else {
          return false;
        }
      });
    },

    // 创建公告请求
    doSubmit(ns) {
      let oData = new FormData();
      const oFileList = this.aFileList;
      const len = oFileList.length - 1;
      oData.append("file", oFileList[len].raw);
      oData.append("NFTID", this.form.NFTID);
      oData.append("airdropType", this.form.airdropType);
      AirdropCreate(oData).then((r) => {
        if (r.code == 0) {
          this.$message({
            type: "success",
            message: "创建成功",
            duration: 1300,
            onClose: () => {
              this.goBack();
              this.codeDisabled = false;
            },
          });
        } else {
          this.$message({
            type: "error",
            message: r.data.msg,
            duration: 1300,
            onClose: () => {
              this.codeDisabled = false;
            },
          });
        }
        this.$refs["titleForm"].clearValidate("versionUrl");
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
</style>